/** @jsx jsx */
import { jsx } from 'theme-ui';

import React from 'react';

const Leaf = (props) => {
  return (
    <svg
      width={props.width ? props.width : '100%'}
      height={props.height ? props.height : '100%'}
      sx={{ fill: props.color }}
      enableBackground="new 0 0 469.343 469.343"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 469.343 469.343"
    >
      <g>
        <g>
          <path
            d="M468.523,6.597c-1.643-3.989-5.547-6.592-9.856-6.592C44.693,0.005,0,171.567,0,245.338c0,95.701,72.875,160,181.333,160
			c136.981,0,165.675-130.347,181.099-200.363C383.168,110.703,428.8,55.663,466.219,18.223
			C469.269,15.173,470.187,10.586,468.523,6.597z M341.589,200.389c-19.563,88.832-49.664,183.616-160.256,183.616
			c-95.701,0-160-55.723-160-138.667c0-65.899,40.789-217.259,412.16-223.787C398.229,60.357,360.32,115.162,341.589,200.389z"
          />
        </g>

        <g>
          <path
            d="M297.6,112.687c-2.581-5.291-8.96-7.488-14.251-4.949C109.973,191.749,0,395.247,0,458.671
			c0,5.888,4.779,10.667,10.667,10.667s10.667-4.779,10.667-10.667c0-49.856,96.235-246.891,271.317-331.733
			C297.941,124.357,300.16,117.978,297.6,112.687z"
          />
        </g>
      </g>
    </svg>
  );
};

export default Leaf;
