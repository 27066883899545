/** @jsx jsx */
import { jsx } from 'theme-ui';

import React from 'react';
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Flex, Box, Text, Heading, Button, Link } from 'theme-ui';
import { AiOutlineInstagram } from 'react-icons/ai';
import { Link as GatsbyLink, graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Trans, t } from '@lingui/macro';
import { BgImage } from 'gbimage-bridge';

import WhyListItem from '../components/WhyListItem';
import New from '../components/Icons/WhyListIcons/new';
import Shampoo from '../components/Icons/WhyListIcons/shampoo';
import Winner from '../components/Icons/WhyListIcons/winner';
import HairIron from '../components/Icons/WhyListIcons/hair-iron';
import Wave from '../components/Icons/WhyListIcons/wave';
import Leaf from '../components/Icons/WhyListIcons/leaf';
import Snap from '../components/Icons/WhyListIcons/snap';

const CtaButton = ({ text }) => (
  <Button
    as={GatsbyLink}
    to="/product/monotape-hair-extensions"
    px={5}
    sx={{
      color: 'white',
      bg: 'black',
      borderRadius: 0,
      border: '0px',
      transition: 'background 0.5s',
      '&:hover,&:focus,&:active': {
        color: 'white',
        bg: 'secondary',
        borderRadius: 0,
        border: '0px',
        textDecoration: 'none',
        outline: 0,
      },
    }}
  >
    {text}
  </Button>
);

const IndexPage = (props) => {
  const { storeName } = props.data.site.siteMetadata.gatsbyStorefrontConfig;
  const { hrefLang } = props.pageContext;
  const { placeholderImage } = props.data;

  const image = getImage(placeholderImage);

  return (
    <>
      <Helmet
        title={t({
          id: `Home.Title`,
          message: `Kisscurls Hair Extensions — Tape-ins Monotapes`,
        })}
        // titleTemplate={`%s — ${storeName}`}
        defer={false}
      >
        <meta
          name="description"
          content={t({
            id: `Home.MetaDescription`,
            message: `Hair Extensions Tape-ins Monotapes`,
          })}
        />
      </Helmet>
      <Flex
        pt={1}
        mx="auto"
        sx={{
          flexWrap: ['wrap', 'nowrap'],
          height: ['100vh', '80vh'],
          minHeight: ['auto', '800px'],
          maxHeight: 900,
        }}
      >
        <Box
          sx={{
            width: ['100%', '50%'],
            height: ['50%', 'auto'],
            position: 'relative',
            minHeight: ['auto', '800px'],
            // maxHeight: '800px',
            overflow: 'hidden',
            bg: '#d5c4bd',
          }}
        >
          <BgImage image={image} style={{ height: '100%', width: '100%' }}>
            <Flex
              sx={{
                position: 'absolute',
                top: 0,
                width: '100%',
                height: '100%',
                px: 2,
                flexDirection: 'column',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Heading
                as="h2"
                sx={{
                  fontSize: 3,
                  // color: '#907368',
                  color: 'black',
                  textTransform: 'uppercase',
                  letterSpacing: hrefLang !== 'ru-BY' ? '4px' : '1px',
                  fontWeight: '500',
                  textAlign: 'center',
                  '-webkit-font-smoothing': 'antialiased',
                  '-moz-osx-font-smoothing': 'grayscale',
                }}
              >
                <Trans id="Home.AboveHeader">Tape-Ins</Trans>
              </Heading>
              <Heading
                as="h1"
                mb={[2, 3, 5]}
                color="white"
                sx={{
                  maxWidth: 600,
                  fontSize: ['40px', '70px', '86px'],
                  lineHeight: ['40px', '70px', '86px'],
                  fontWeight: '400',
                  textAlign: 'center',
                  '-webkit-font-smoothing': 'antialiased',
                  '-moz-osx-font-smoothing': 'grayscale',
                }}
              >
                <Trans id="Home.Header">Monotape Hair Extensions</Trans>
              </Heading>

              <Text
                as="div"
                mb={[2, 3, 5]}
                mx={4}
                sx={{
                  textAlign: 'center',
                  verticalAlign: 'middle',
                  fontSize: [2, 3],
                }}
              >
                <Trans id="Home.HeaderSecondary">
                  Easy to self-install at home
                </Trans>{' '}
                <span
                  role="img"
                  sx={{
                    fontSize: [30, 36],
                    verticalAlign: 'middle',
                  }}
                >
                  🏡
                </span>
              </Text>

              <CtaButton
                text={t({ id: 'Home.HeaderButton', message: 'SHOP NOW' })}
              />
            </Flex>
          </BgImage>
        </Box>
        <Box
          sx={{
            width: ['100%', '50%'],
            height: ['50%', 'auto'],
            position: 'relative',
            minHeight: ['auto', '800px'],
            // maxHeight: '800px',
            overflow: 'hidden',
            bg: '#d5c4bd',
          }}
        >
          <StaticImage
            src="../images/hero/hero.jpg"
            alt={t({
              id: `Home.HearoImageAlt`,
              message: `A girl wearing Kisscurls Monotapes`,
            })}
            placeholder="blurred"
            layout="fullWidth"
            sx={{ width: ['100%', 'auto'], height: '100%' }}
          />

          {/* <video
            autoPlay="autoplay"
            loop="loop"
            muted
            sx={{ width: ['100%', 'auto'], height: '100%' }}
          >
            <source
              src="https://ak.picdn.net/shutterstock/videos/1062391180/preview/stock-footage-beautiful-cheerful-ginger-redhead-young-girl-wearing-white-pink-sweater-posing-isolated-on-blue.mp4"
              type="video/mp4"
            />
          </video> */}
        </Box>
      </Flex>

      <Box bg="#f4f1eb">
        <Flex
          py={[4, 5]}
          mx="auto"
          sx={{
            flexWrap: 'wrap',
            width: '100%',
            maxWidth: 1300,
            justifyContent: 'center',
            height: 'auto',
          }}
        >
          <Heading
            sx={{
              my: 3,
              width: '100%',
              textAlign: 'center',
              fontSize: [4, 5],
              color: '#907368',
              textTransform: 'uppercase',
              letterSpacing: '4px',
              fontWeight: '500',
              '-webkit-font-smoothing': 'antialiased',
              '-moz-osx-font-smoothing': 'grayscale',
            }}
          >
            <Trans id="Home.WhyKisscurlsHeader">Why Kisscurls?</Trans>
          </Heading>

          <WhyListItem
            heading={t({
              id: `Home.WhyListItem1_heading`,
              message: `Innovative monotapes`,
            })}
            text={t({
              id: `Home.WhyListItem1_text`,
              message: `70-90 grams of hair in just 4 monotapes`,
            })}
            icon={<Winner width={64} height={64} color="secondary" />}
          />

          <WhyListItem
            heading={t({
              id: `Home.WhyListItem2_heading`,
              message: `Easy to self-install`,
            })}
            text={t({
              id: `Home.WhyListItem2_text`,
              message: `You can install monotapes in just 20 minutes at home`,
            })}
            icon={<Snap width={64} height={64} color="secondary" />}
          />

          <WhyListItem
            heading={t({
              id: `Home.WhyListItem3_heading`,
              message: `Reusable with proper care`,
            })}
            text={t({
              id: `Home.WhyListItem3_text`,
              message: `Re-install every 5-8 weeks`,
            })}
            icon={<HairIron width={54} height={54} color="secondary" />}
          />

          <WhyListItem
            heading={t({
              id: `Home.WhyListItem4_heading`,
              message: `100% European remy human hair`,
            })}
            text={t({
              id: `Home.WhyListItem4_text`,
              message: `Natural hair with soft and silky texture without silicons`,
            })}
            icon={<Wave width={54} height={64} color="secondary" />}
          />

          <WhyListItem
            heading={t({
              id: `Home.WhyListItem5_heading`,
              message: `For everyday wear`,
            })}
            text={t({
              id: `Home.WhyListItem5_text`,
              message: `Wash, style and cut just like your own hair`,
            })}
            icon={<Shampoo width={54} height={64} color="secondary" />}
          />

          <WhyListItem
            heading={t({
              id: `Home.WhyListItem6_heading`,
              message: `Safe for your hair`,
            })}
            text={t({
              id: `Home.WhyListItem6_text`,
              message: `No special tools, no heat, no chemicals required for home installation`,
            })}
            icon={<Leaf width={54} height={64} color="secondary" />}
          />
        </Flex>
        <Box sx={{ textAlign: 'center', pb: [4, 5] }}>
          <CtaButton text={t({ id: 'Home.WhyCtaButton', message: 'More' })} />
        </Box>
      </Box>

      <Flex
        py={[4, 5]}
        mx="auto"
        sx={{
          flexWrap: 'wrap',
          width: '100%',
          maxWidth: 1300,
          justifyContent: 'center',
        }}
      >
        <Heading
          sx={{
            mt: 3,
            mb: [4, 6],
            width: '100%',
            textAlign: 'center',
            fontSize: [4, 5],
            color: '#907368',
            textTransform: 'uppercase',
            letterSpacing: '4px',
            fontWeight: '500',
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
          }}
        >
          <Trans id="Home.KisscurlsLook">Kisscurls look</Trans>
        </Heading>
        <Box
          sx={{ position: 'relative', bg: '#d5c4bd', height: ['auto', 500] }}
        >
          <Text
            as="div"
            sx={{
              position: ['relative', 'absolute'],
              width: ['100%', 'auto'],
              writingMode: ['horizontal-tb', 'vertical-rl'],
              textOrientation: 'upright',
              textAlign: 'center',
              color: 'white',
              p: 2,
            }}
          >
            <Trans id="Home.KisscurlsLookBefore">Before</Trans>
          </Text>
          <StaticImage
            src="../images/kisscurls-look-before.jpg"
            alt={t({
              id: `Home.KisscurlsLookBeforeImageAlt`,
              message: `Kisscurls Look Before`,
            })}
            placeholder="blurred"
            layout="constrained"
            width={400}
            height={500}
            sx={{
              position: 'relative',
              top: [0, -50],
              right: [0, -50],
            }}
          />
        </Box>
        <Box
          sx={{
            position: 'relative',
            bg: '#d5c4bd',
            height: ['auto', 500],
            ml: [0, 100],
          }}
        >
          <Text
            as="div"
            sx={{
              position: ['relative', 'absolute'],
              width: ['100%', 'auto'],
              writingMode: ['horizontal-tb', 'vertical-rl'],
              textOrientation: 'upright',
              textAlign: 'center',
              color: 'white',
              p: 2,
            }}
          >
            <Trans id="Home.KisscurlsLookAfter">After</Trans>
          </Text>
          <StaticImage
            src="../images/kisscurls-look-after.jpg"
            alt={t({
              id: `Home.KisscurlsLookAfterImageAlt`,
              message: `Kisscurls Look After`,
            })}
            placeholder="blurred"
            layout="constrained"
            width={400}
            height={500}
            sx={{
              position: 'relative',
              top: [0, -50],
              right: [0, -50],
            }}
          />
        </Box>
      </Flex>

      <Flex
        pt={[4, 5]}
        mx="auto"
        sx={{
          flexWrap: 'wrap',
          width: '100%',
          justifyContent: 'center',
          height: ['auto'],
        }}
      >
        <Heading
          sx={{
            my: 3,
            width: '100%',
            textAlign: 'center',
            fontSize: [4, 5],
            color: '#907368',
            // textTransform: 'uppercase',
            letterSpacing: '4px',
            fontWeight: '500',
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
          }}
        >
          #kisscurlsgirls
        </Heading>
        <Text mb={[2, 4]} sx={{ width: '100%', textAlign: 'center' }}>
          <Trans id="Home.FollowUs">
            Follow{' '}
            <Link href="https://www.instagram.com/thekisscurls/">
              <AiOutlineInstagram sx={{ mb: 0.5 }} />
              thekisscurls
            </Link>{' '}
            and tag{' '}
            <Link href="https://www.instagram.com/explore/tags/kisscurlsgirls/">
              #kisscurlsgirls
            </Link>{' '}
            to be featured
          </Trans>
        </Text>

        <Flex sx={{ flexWrap: ['wrap', 'nowrap'] }}>
          <StaticImage
            src="../images/instagram/instagram1.jpg"
            alt={t({
              id: `Home.Instagram1_ImageAlt`,
              message: `A girl wearing Kisscurls Monotapes`,
            })}
            placeholder="blurred"
            layout="constrained"
            width={500}
            height={500}
          />

          {/* <StaticImage
            src="../images/instagram/instagram2.jpg"
            alt="Hair monotapes"
            placeholder="blurred"
            layout="constrained"
            width={500}
            height={500}
          /> */}

          <StaticImage
            src="../images/instagram/instagram3.jpg"
            alt={t({
              id: `Home.Instagram3_ImageAlt`,
              message: `A girl with tape-ins hair extensions`,
            })}
            placeholder="blurred"
            layout="constrained"
            width={500}
            height={500}
          />

          {/* <StaticImage
            src="../images/instagram/instagram4.jpg"
            alt="Kisscurls hair extensions before and after"
            placeholder="blurred"
            layout="constrained"
            width={500}
            height={500}
          /> */}

          <StaticImage
            src="../images/instagram/instagram5.jpg"
            alt={t({
              id: `Home.Instagram5_ImageAlt`,
              message: `A girl with tape-ins hair extensions`,
            })}
            placeholder="blurred"
            layout="constrained"
            width={500}
            height={500}
          />

          {/* <StaticImage
            src="../images/instagram/instagram6.jpg"
            alt="Adding hair volume with monotape hair extensions"
            placeholder="blurred"
            layout="constrained"
            width={500}
            height={500}
          /> */}

          <StaticImage
            src="../images/instagram/instagram8.jpg"
            alt={t({
              id: `Home.Instagram8_ImageAlt`,
              message: `A girl with tape-ins hair extensions`,
            })}
            placeholder="blurred"
            layout="constrained"
            width={500}
            height={500}
          />

          <StaticImage
            src="../images/instagram/instagram9.jpg"
            alt={t({
              id: `Home.Instagram9_ImageAlt`,
              message: `A girl with tape-ins hair extensions`,
            })}
            placeholder="blurred"
            layout="constrained"
            width={500}
            height={500}
          />
        </Flex>
      </Flex>
    </>
  );
};

export const IndexPageQuery = graphql`
  query {
    site {
      siteMetadata {
        gatsbyStorefrontConfig {
          storeName
        }
      }
    }
    placeholderImage: file(relativePath: { eq: "monotapes-hero.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
      }
    }
  }
`;

export default IndexPage;
