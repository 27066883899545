/** @jsx jsx */
import { jsx } from 'theme-ui';

import React from 'react';

const Winner = (props) => {
  return (
    <svg
      width={props.width ? props.width : '100%'}
      height={props.height ? props.height : '100%'}
      sx={{ fill: props.color }}
      id="Layer_1"
      enableBackground="new 0 0 512.004 512.004"
      viewBox="0 0 512.004 512.004"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <path d="m446.002 332.003h-110v-50.001c0-16.542-13.458-30-30-30h-100c-16.542 0-30 13.458-30 30v10.001h-110c-16.542 0-30 13.458-30 30v180.001c0 5.523 4.477 10 10 10h420c5.523 0 10-4.477 10-10v-140.001c0-16.542-13.458-30-30-30zm-130 160h-120v-25.003c0-5.523-4.477-10-10-10s-10 4.477-10 10v25.003h-120v-170.001c0-5.514 4.486-10 10-10h110v64.998c0 5.523 4.477 10 10 10s10-4.477 10-10v-94.999c0-5.514 4.486-10 10-10h100c5.514 0 10 4.486 10 10zm140 .001h-120v-140.001h110c5.514 0 10 4.486 10 10z" />
          <path d="m421.025 402.003c0-16.542-13.417-30-29.908-30-8.746 0-17.027 3.825-22.721 10.493-3.586 4.2-3.088 10.512 1.111 14.099 4.201 3.586 10.512 3.089 14.099-1.111 1.888-2.211 4.626-3.48 7.511-3.48 5.463 0 9.908 4.486 9.908 10 0 2.717-1.063 5.259-2.991 7.158-2.133 2.1-3.213 5.045-2.943 8.026s1.86 5.685 4.335 7.368c4.118 2.801 6.576 7.454 6.576 12.448 0 8.271-6.677 15-14.884 15-3.292 0-6.41-1.061-9.018-3.067-4.377-3.367-10.655-2.548-14.024 1.828-3.368 4.377-2.549 10.656 1.828 14.024 6.136 4.721 13.471 7.216 21.214 7.216 19.235 0 34.884-15.701 34.884-35 0-8.171-2.833-15.955-7.852-22.125 1.883-3.97 2.875-8.343 2.875-12.877z" />
          <path d="m136 412.004h-24.097c2.203-2.025 4.963-4.193 8.184-6.679 11.437-8.824 25.669-19.806 25.912-40.492.178-15.193-9.539-28.313-23.63-31.907-13.471-3.434-26.606 2.697-33.462 15.622-2.588 4.879-.73 10.932 4.149 13.52 4.88 2.588 10.932.73 13.52-4.149 3.406-6.422 8.261-6.272 10.851-5.613 4.327 1.104 8.656 5.333 8.574 12.293-.12 10.262-6.93 16.25-18.13 24.892-10.25 7.909-21.868 16.873-21.868 32.513 0 5.523 4.477 10 10 10h39.997c5.523 0 10-4.477 10-10s-4.477-10-10-10z" />
          <path d="m267.089 372.002h-1.087v-69.999c0-3.573-1.906-6.874-5-8.66-3.093-1.787-6.905-1.786-10 0l-11.087 6.401c-4.783 2.761-6.422 8.877-3.66 13.66 2.044 3.54 5.928 5.355 9.747 4.943v53.655h-1.087c-5.523 0-10 4.477-10 10s4.477 10 10 10h22.174c5.523 0 10-4.477 10-10s-4.477-10-10-10z" />
          <path d="m188.658 147.109-5.013 38.222c-1.049 5.517-.304 11.097 2.163 16.163 4.482 9.204 13.729 14.566 23.338 14.566 3.801 0 7.661-.84 11.301-2.613l35.151-17.11 36.117 17.206c4.55 2.172 9.632 2.949 14.696 2.249 6.85-.947 12.92-4.505 17.094-10.019 4.173-5.514 5.949-12.322 5-19.185l-5.417-38.763 26.685-28.146c4.011-3.84 6.633-8.759 7.59-14.246 1.19-6.818-.346-13.691-4.325-19.354s-9.926-9.437-16.719-10.623l-38.521-6.824-19.097-35.129c0-.002-.001-.003-.002-.005-2.422-4.45-6.068-8.063-10.544-10.451-6.095-3.25-13.091-3.933-19.7-1.922-6.608 2.011-12.038 6.476-15.291 12.575l-18.392 34.525-38.695 7.19c-5.248.829-10.046 3.215-13.889 6.911-4.982 4.792-7.799 11.238-7.933 18.15s2.432 13.462 7.232 18.452zm-12.604-50.368c1.117-1.075 2.351-1.451 3.188-1.577.114-.017.227-.036.34-.057l43.579-8.098c2.992-.556 5.568-2.444 6.999-5.13l20.654-38.771c.736-1.381 1.967-2.393 3.465-2.849.564-.172 1.141-.257 1.714-.257.949 0 1.89.233 2.75.692 1.017.542 1.843 1.36 2.39 2.365l21.395 39.355c1.453 2.674 4.045 4.54 7.042 5.071l43.288 7.668c1.555.271 2.911 1.132 3.819 2.424s1.258 2.86.987 4.417c-.282 1.613-1.125 2.683-1.782 3.296-.149.139-.294.283-.435.431l-30.184 31.837c-2.094 2.208-3.068 5.25-2.647 8.264l6.081 43.518c.215 1.558-.188 3.106-1.138 4.36-.949 1.253-2.329 2.063-3.886 2.278-1.175.163-2.333-.006-3.349-.49l-40.471-19.28c-2.748-1.309-5.941-1.295-8.677.037l-39.482 19.218c-2.931 1.429-6.477.205-7.904-2.727-.728-1.495-.661-2.875-.477-3.77.049-.238.089-.477.121-.718l5.681-43.315c.396-3.017-.604-6.05-2.716-8.241l-30.505-31.639c-1.088-1.132-1.671-2.62-1.641-4.191.03-1.568.669-3.033 1.801-4.121z" />
          <path d="m99.502 18.662 39.474 22.79c1.575.91 3.294 1.342 4.991 1.342 3.456 0 6.817-1.794 8.669-5.002 2.762-4.783 1.123-10.899-3.66-13.66l-39.474-22.79c-4.782-2.762-10.899-1.123-13.66 3.66-2.762 4.783-1.123 10.899 3.66 13.66z" />
          <path d="m70.645 138.548c.479 0 .964-.035 1.453-.105l45.105-6.563c5.465-.795 9.251-5.871 8.456-11.336-.796-5.465-5.868-9.25-11.336-8.456l-45.105 6.563c-5.465.795-9.251 5.871-8.456 11.336.725 4.977 4.998 8.561 9.883 8.561z" />
          <path d="m139.354 199.397-32.23 32.23c-3.905 3.905-3.905 10.237 0 14.143 1.953 1.953 4.512 2.929 7.071 2.929s5.119-.976 7.071-2.929l32.23-32.23c3.905-3.905 3.905-10.237 0-14.143s-10.237-3.905-14.142 0z" />
          <path d="m368.038 42.794c1.696 0 3.416-.433 4.991-1.342l39.474-22.79c4.783-2.761 6.422-8.877 3.66-13.66-2.762-4.784-8.877-6.422-13.66-3.66l-39.474 22.79c-4.783 2.761-6.422 8.877-3.66 13.66 1.851 3.208 5.213 5.002 8.669 5.002z" />
          <path d="m386.345 120.544c-.795 5.465 2.991 10.541 8.456 11.336l45.105 6.563c.488.071.973.105 1.453.105 4.885 0 9.159-3.584 9.883-8.562.795-5.465-2.991-10.541-8.456-11.336l-45.105-6.563c-5.461-.792-10.541 2.992-11.336 8.457z" />
          <path d="m358.507 199.397c-3.905 3.905-3.905 10.237 0 14.143l32.23 32.23c1.953 1.953 4.512 2.929 7.071 2.929s5.119-.976 7.071-2.929c3.905-3.905 3.905-10.237 0-14.143l-32.23-32.23c-3.904-3.905-10.236-3.905-14.142 0z" />
          <path d="m186.002 412c-5.523 0-10 4.48-10 10.003s4.477 10 10 10 10-4.477 10-10v-.007c0-5.522-4.477-9.996-10-9.996z" />
        </g>
      </g>
    </svg>
  );
};

export default Winner;
