/** @jsx jsx */
import { jsx } from 'theme-ui';

import React from 'react';
import { Flex, Box, Text } from 'theme-ui';

export default function WhyListItem({ heading, text, icon }) {
  return (
    <>
      <Flex my={3} mx={4}>
        <Flex
          color="secondary"
          mr={3}
          pt={1}
          sx={{ alignItems: 'flex-start', justifyContent: 'center', width: 64 }}
        >
          {icon}
        </Flex>
        <Box sx={{ width: [400, 250] }}>
          <Text as="h4" sx={{ fontWeight: 'bold' }}>
            {heading}
          </Text>
          <Text>{text}</Text>
        </Box>
      </Flex>
    </>
  );
}
